






























































































import { Component, Vue } from 'vue-property-decorator';

@Component({
  name: 'Logo42',
})
export default class Logo42 extends Vue {}
